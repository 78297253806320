<template>
    <v-container fluid>
        <tool-bar :toolbar="toolbar" @editCategory="editAccess()"></tool-bar>
        <v-simple-table fixed-header :height="tableHeight" class="mainTable">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th rowspan="2" class="text-center simple-table=header">{{ $t('global.accountType') }}</th>
                        <th colspan="5" class="text-center" style="border-bottom: 1px solid white !important;">{{ $t('global.accessSettings') }}</th>
                    </tr>
                    <tr>
                        <template v-for="(access, accessInd) in displayAccessSettings">
                            <th class="text-center simple-table=header" :key="accessInd+'accessInd1'">{{ $t(access.name) }} </th>
                        </template>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(account, accountInd) in displayAccountType">
                            <tr :key="accountInd + 'displayAccessRights1'">
                                    <td class="table-font-size">
                                        <span class="ml-8">{{account.name}} </span>
                                        <v-icon v-if="!expanded.includes(account.grp_id) && account.grp_id" @click="pushToExpand(account.grp_id)" class="mb-1">mdi-menu-down</v-icon>
                                        <v-icon v-if="expanded.includes(account.grp_id) && account.grp_id" @click="removeExpand(account.grp_id)" class="mb-1">mdi-menu-up</v-icon>
                                    </td>
                                    <template v-if="!editMode">
                                        <template v-if="account.name != 'GC Leader' && account.name != 'GC Member'">
                                            <template v-for="(master, masterInd) in displayAccessSettings">
                                                <template v-for="(access, accesInd) in accessRightData">
                                                    <template v-if="access.account_type == account.name">
                                                        <template v-if="master.id == access.master_id">
                                                            <td :key="`${masterInd}${accesInd}masterAccessIndexes`" class="text-center">
                                                                <v-icon>
                                                                    {{
                                                                        access.access_right == 1 ? 'mdi-eye'
                                                                            : access.access_right == 2 ? 'mdi-pencil'
                                                                                : access.access_right == 3 ? 'mdi-close' : 'mdi-account-check'
                                                                    }}
                                                                </v-icon>
                                                            </td>
                                                        </template>
                                                        
                                                    </template>
                                                    
                                                </template>
                                                    <template v-if="!getAccountTypeKey(account, master)">
                                                        <td :key="accountInd+masterInd+'accountIndmasterInd1'" class="text-center">
                                                            <v-icon>mdi-close</v-icon>
                                                        </td>
                                                    </template>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <template v-for="(master, masterInd) in displayAccessSettings">
                                                <td :key="`${masterInd}displaymasters`" class="text-center">
                                                    -
                                                </td>
                                            </template>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <template v-if="account.name != 'GC Leader' && account.name != 'GC Member'">
                                            <template v-for="(master, masterInd) in displayAccessSettings">
                                                <template v-for="(access, accesInd) in accessRightData">
                                                    <template v-if="access.account_type == account.name">
                                                        <template v-if="master.id == access.master_id">
                                                            <!-- <td :key="accesInd+masterInd+'accesIndmasterInd1'" class="text-center"> -->
                                                            <td :key="`${accesInd}${masterInd}accesIndmasterInd1`" class="text-center">
                                                                <v-btn small @click="editAccessRight(account.name, master.id)">
                                                                    <v-icon class="text-center" color="#666666">
                                                                        {{
                                                                            access.access_right == 1 ? 'mdi-eye'
                                                                                : access.access_right == 2 ? 'mdi-pencil'
                                                                                    : access.access_right == 3 ? 'mdi-close' : 'mdi-account-check'
                                                                        }}
                                                                    </v-icon>
                                                                </v-btn>
                                                            </td>
                                                        </template>
                                                        
                                                    </template>
                                                    
                                                </template>
                                                <template v-if="!getAccountTypeKey(account, master)">
                                                    <td :key="accountInd+masterInd+'accesIndmasterInd2'" class="text-center">
                                                        <v-btn small @click="editAccessRight(account.name, master.id)">
                                                            <v-icon class="text-center" color="#666666">
                                                                mdi-close
                                                            </v-icon>
                                                        </v-btn>
                                                    </td>
                                                </template>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <template v-for="(master, masterInd) in displayAccessSettings">
                                                <td :key="`${masterInd}displaymasters2`" class="text-center">
                                                    -
                                                </td>
                                            </template>
                                        </template>
                                    </template>
                                </tr>
                                <template v-for="(grp, grpInd) in account.sub_group">
                                    <tr v-if="expanded.length && expanded.includes(account.grp_id)" :key="`${accountInd}${grpInd}displaySubGroup`">
                                        <td class="text-center">{{grp}}</td>

                                        <template v-if="!accessRightData.length">
                                            <template v-for="(master, masterInd) in displayAccessSettings">
                                                <template v-if="!editMode">
                                                    <td class="text-center" :key="`${accountInd}${grpInd}${masterInd}displayMasterAcessSubgrpSettings`">
                                                        <v-icon>mdi-close</v-icon>
                                                    </td>
                                                </template>
                                                <template v-else>
                                                    <td class="text-center" :key="`${accountInd}${grpInd}${masterInd}displayMasterAcessSubgrpSettings`">
                                                        <v-btn small @click="editAccessRight(account.name, master.id, grp)">
                                                            <v-icon class="text-center" color="#666666">
                                                                mdi-close
                                                            </v-icon>
                                                        </v-btn>
                                                    </td>
                                                </template>
                                            </template>
                                        </template>
                                        <template v-else>
                                                <template v-if="account.name == 'GC Leader' || account.name == 'GC Member'">
                                                    <template v-for="(master, masterInd) in displayAccessSettings">
                                                        <template v-for="(subAccess, subAccessInd) in accessRightData">
                                                            <template v-if="account.name == subAccess.account_type">
                                                                <template v-if="grp == subAccess.sub_group">
                                                                    <template v-if="master.id == subAccess.master_id">
                                                                        <td :key="`${masterInd}${subAccessInd}subGrpDetails`" class="text-center">
                                                                            <template v-if="!editMode">
                                                                                <v-icon class="text-center" color="#666666">
                                                                                    {{
                                                                                        subAccess.access_right == 1 ? 'mdi-eye'
                                                                                            : subAccess.access_right == 2 ? 'mdi-pencil'
                                                                                                : subAccess.access_right == 3 ? 'mdi-close' : 'mdi-account-check'
                                                                                    }}
                                                                                </v-icon>
                                                                            </template>
                                                                            <template v-else>
                                                                                <v-btn small @click="editAccessRight(account.name, master.id, grp)">
                                                                                    <v-icon class="text-center" color="#666666">
                                                                                        {{
                                                                                            subAccess.access_right == 1 ? 'mdi-eye'
                                                                                                : subAccess.access_right == 2 ? 'mdi-pencil'
                                                                                                    : subAccess.access_right == 3 ? 'mdi-close' : 'mdi-account-check'
                                                                                        }}
                                                                                    </v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                        </td>
                                                                    </template>
                                                                </template>
                                                            </template>
                                                        </template>
                                                        <template>
                                                            <template v-if="!getSubGrp(account.name, master.id, grp)">
                                                                <td :key="`${grpInd}${masterInd}grpDisplayMode`" class="text-center">
                                                                    <template v-if="!editMode">
                                                                        <v-icon class="text-center">mdi-close</v-icon>
                                                                    </template>
                                                                    <template v-else>
                                                                        <v-btn small @click="editAccessRight(account.name, master.id, grp)">
                                                                            <v-icon class="text-center" color="#666666">mdi-close</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                </td>
                                                            </template>
                                                        </template>
                                                    </template>
                                                </template>
                                        </template>
                                    </tr>
                                </template>
                    </template>
                </tbody>
            </template>
        </v-simple-table>
        
        <v-dialog
            v-model="insertDialog"
            persistent
            width="350"
            @keydown.esc="insertDialog = false"
            scrollable
        >
            <v-form
                id="Insert"
                ref="Insert"
                @submit.prevent="Insert"
                enctype="multipart/form-data"
            >
                <v-card>
                    <v-card-title class="card-title">
                        <span class="title-headline">Add New Admin</span>
                        <v-spacer></v-spacer>
                        <v-icon color="white" @click="insertDialog = false">mdi-close</v-icon>
                    </v-card-title>
                    <v-card-text class="card-text">
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-autocomplete
                                        v-model="add.master_id"
                                        :items="displayRoutes"
                                        item-text="name"
                                        item-value="id"
                                        label="Master"
                                        outlined
                                        dense
                                        class="required"
                                        persistent-placeholder
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn type="submit" class="mr-3 mb-2 mt-2" color="#0C2D48" style="color: white; height: 28px !important;" dense>{{ $t('global.submit') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

        <v-row justify-center>
            <v-dialog 
                v-model="isEditted"
                width="300"
                persistent
                @keydown.esc="isEditted = false;"
            >
                <v-form
                    id="updateAccessRight"
                    @submit.prevent="updateAccessRight"
                    enctype="multipart/form-data"
                >
                    <v-card>
                        <v-card-title class="card-title">
                            <v-icon dark class="mr-2">mdi-pencil-box-multiple-outline</v-icon>
                            <span class="title-headline" style="font-size: 15px !important;">Edit Access Right</span>
                        </v-card-title>
                        <v-card-text class="card-text">
                            <v-container>
                                <v-row>
                                    <v-col cols="12" class="mt-1">
                                        <v-autocomplete
                                            v-model="tempAccessRight"
                                            label="Access Right"
                                            :items="displayAccessRights"
                                            item-text="type"
                                            item-value="id"
                                            dense
                                            outlined
                                            autocomplete="off"
                                            persistent-placeholder
                                        >
                                        </v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn small outlined color="#B71C1C" @click="isEditted = false">Cancel</v-btn>
                            <v-btn small type="submit" outlined color="#1d3244">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-dialog>
        </v-row>

        <float-action 
            :floatbtn="floatbtn"
            :insertDialog="insertDialog"
            @toggleInsertDialog="toggleInsertDialog($event)"
        ></float-action>

        <snack-bar :snackbar="snackbar">

        </snack-bar>
    </v-container>
</template>
<script>
    import axios from 'axios'
    import router from '../../router/index'
    import Toolbar from '../includes/Toolbar.vue'
    import FloatAction from '../includes/FloatAction.vue'
    import SnackBar from '../includes/SnackBar.vue'
import { mapActions, mapState } from 'vuex'


    export default {
        components: {
            "tool-bar": Toolbar,
            "float-action": FloatAction,
            "snack-bar": SnackBar,
        },
        data: () => ({
            toolbar: {
                title: 'toolbar.homeTitle',
                sub_title: 'toolbar.adminSettings',
                editAccessRight: true,
            },
            floatbtn: {
                edit: false ,
            },
            snackbar: {
                isUpdated: false,
                color: "blue-grey-darken-2",
                text: "There's a new update. Please click 'REFRESH'.",
            },
            insertDialog: false,
            tableHeight: window.innerHeight - 160,
            add: {},
            accessRights: [
                {
                    id: 1,
                    type: 'Read'
                },
                {
                    id: 2,
                    type: 'Write'
                },
                {
                    id: 3,
                    type: 'Hide'
                },
            ],
            accessMasterData: [],
            tempAccessRight: 3,
            accessMenu: [],
            menuArr: [],
            isUpdated: false,
            gcLeaderDown: false,
            expanded: [],
            isEditted: false,
            tempData: {},
        }),
        computed:{
            ...mapState(['accessRightData', 'subGroupData']),
            displayAccessRights() {
                if(this.tempData.master_id == 6) {
                    return this.accessRights.filter(res => res.id == 2 || res.id == 3);
                } else if(this.tempData.master_id == 8) {
                    return this.accessRights.filter(res => res.id == 1 || res.id == 3);
                } else {
                    return this.accessRights;
                }

            },
            displayRoutes() {
                let routes = router.options.routes

                let mappedRoutes = routes.map(data => {
                    if(data.name != "Home" && data.name != "login" && data.name != "IndustryCategory"
                        && data.name != "GC") {
                            return [{
                                id: data.meta.id,
                                name: data.name
                            }]
                        }
                }).filter(r => r).flat();
                return mappedRoutes;
            },
            displayAccessSettings() {
                // let header = router.options.routes;

                // return header.filter(head => {
                //     if(head.name == 'Industry Master' || head.name == 'GC' || head.name == 'Email Master') {
                //         return head;
                //     }
                // }).map(m => {
                //     return {id: m.meta.id, name: m.name}
                // })
                return [
                    {
                        id: 3,
                        name: 'navigation.emailMaster',
                    },
                    {
                        id: 4,
                        name: 'navigation.industryMaster',
                    },
                    {
                        id: 6,
                        name: 'navigation.gc',
                    },
                    {
                        id: 12,
                        name: 'navigation.OrderHistory',
                    }
                ]
                
            },
            displayAccountType() {
                return [
                    {
                        name: 'HRD Admin'
                    },
                    {
                        name: 'HRD User'
                    },
                    {
                        grp_id: 1,
                        name: 'GC Leader',
                        sub_group: this.subGroupData.value
                    },
                    {
                        grp_id: 2,
                        name: 'GC Member',
                        sub_group: this.subGroupData.value
                    },
                    {
                        name: 'Nihon Sangyou',
                    },
                    // {
                    //     name: '日本産業'
                    // }
                ];
            }
        },
        methods: {
            ...mapActions(['getAccessRights']),
            getSubGrp(accountName, masterId, subGrp){
                const exists = this.accessRightData.find(rec => rec.account_type == accountName && rec.sub_group == subGrp && rec.master_id == masterId)
                if(!exists) return '';
                if(exists) return exists.master_id
            },
            getAccountTypeKey(accountData, masterData){
                const exists = this.accessRightData.find(rec => rec.account_type == accountData.name && rec.master_id == masterData.id)

                if(!exists) return '';
                if(exists) return exists.account_type
            },
            cancelMenu(){
                this.menuArr.splice(0);
            },
            toggleInsertDialog(val) {
                this.insertDialog = val;
            },
            async Insert() {
                this.add.user_id = this.loggedInUser.user_id

                await axios.post(`${window.api}/mysql/accessMaster`, this.add)
                    .then(res => {
                        if(res.data) {
                            // console.log(res.data, 'Insert')
                        }
                    })
            },
            pushToExpand(val){
                this.expanded.push(val);
            },
            removeExpand(val){
                let index = this.expanded.indexOf(val)
                this.expanded.splice(index,1)
            },
            editAccessRight(accountType, masterId, subGroup) {
                let isExists = this.accessRightData.find(data => data.account_type == accountType && data.master_id == masterId && data.sub_group == subGroup)

                if(isExists) {
                    this.tempAccessRight = isExists.access_right
                }

                this.tempData = {
                    account_type: accountType,
                    master_id: masterId,
                    sub_group: !subGroup ? null : subGroup,
                }
                this.isEditted = true;
            },
            async updateAccessRight() {
                this.tempData['access_right'] = this.tempAccessRight

                    axios({
                        method: 'post',
                        url: `${window.api}/mysql/updateAccessRight`,
                        data: this.tempData,
                        headers: {
                            'x-api-key' : process.env.VUE_APP_HEADERS
                        }
                    }).then(res => {
                        if(res.data) {
                            this.expanded.splice(0);
                            this.$store.state.editMode = false
                            this.isEditted = false;

                            this.$socket.emit('gcTsuikaData', {
                                system: 'gc-tsuika',
                                request: 'accessRightsUpdate',
                                requestData: this.loggedInUser.user_id,
                                dispatchType: 'request-local',
                                port: this.windowLoc.port,
                                protocol: this.windowLoc.protocol,
                                hostname: this.windowLoc.hostname,
                            })

                            location.reload(true);
                        }
                    })
            },
            editAccess() {
                if(!this.$store.state.editMode) {
                    this.$store.state.editMode = true;
                } else {
                    this.$store.state.editMode = false;
                }
            }
        },
        watch: {
            isUpdated(val) {
                if(val) {
                    this.menuArr.splice(0);
                    this.isUpdated = false;
                }
            },
            editMode(val) {
                if(val) {
                    this.floatbtn.add = false;
                }
                if(!val) {
                    this.floatbtn.add = false; 
                    this.menuArr.splice(0);
                }
            },
            isEditted(val) {
                if(!val) {
                    this.tempAccessRight = 3;
                }
            }
        },
        mounted() {
        },
    }

</script>
<style scoped>
.no-background-hover::before {
    background-color: #e7effd !important;
}
</style>