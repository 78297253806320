<template>
    <v-list-item class="title-page">
        <template>
            <!-- <v-tooltip v-if="toolbar.back">
                <template v-slot:activator="{ on }">
                    <v-btn rounded color="#274761" dark small class="mt-3 mb-3" v-on="on">
                        <v-icon class="ml-1" size="18" color="#fff" style="font-weight: bolder;">mdi-arrow-left-thick</v-icon>
                    </v-btn>
                </template>
                <span>Back</span>
            </v-tooltip> -->
            <v-btn text small class="mb-2" v-if="toolbar.back" @click="backToMasterCategory()">
                <v-icon>mdi-arrow-left-thick</v-icon>
            </v-btn>
            <v-list-item-content class="content-margin">
                <v-list-item-title>
                    <span data-cad-step="1">{{ $t(toolbar.title) }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>
                    {{ $t(toolbar.sub_title) }}
                </v-list-item-subtitle>
            </v-list-item-content>

            <template v-if="toolbar.orderHistory == true">
                <!-- <v-icon small class="mr-1">mdi-magnify</v-icon> -->
                <!-- <v-icon color="#274761" fab style="font-size: 15px; font-weight: bolder;" class="ml-1">mdi-magnify</v-icon> -->
                <!-- <a-input
                    class="mr-1"
                    left
                    v-model="search"
                    :placeholder="$t('global.search')"
                    style="width: 250px"
                    >
                </a-input> -->
                <template v-if="toolbar.orderCode && toolbar.orderCategoryCode">
                    <v-btn small outlined class="mr-1">
                        <span>HOUSE TYPE: {{ toolbar.houseType }} </span>
                    </v-btn>
                    <v-btn small outlined class="mr-1">
                        <span>CODE: {{ toolbar.orderCode }} </span>
                        <span class="ml-1 mr-1"> - </span>
                        <span> {{ toolbar.orderCategoryCode }} </span>
                    </v-btn>
                    <!-- <v-btn small outlined class="mr-1">
                        <span>ORG JUSETSU DELIVERY: {{ toolbar.orgJusestsuDeliver }} </span>
                    </v-btn> -->
                </template>
                <v-btn @click="toggleSearch()" color="#274761" small icon outlined>
                    <v-icon small>mdi-magnify</v-icon>
                </v-btn>
            </template>
            <v-tooltip left v-if="toolbar.backToOrderHistory">
                <template v-slot:activator="{ on }">
                    <a>
                        <v-btn @click="toggleBackHistory()" class="mb-1" icon v-on="on">
                            <v-icon size="22" color="#274761" style="font-weight: bolder;">mdi-arrow-left</v-icon>
                        </v-btn>
                    </a>
                </template>
                <span>Back</span>
            </v-tooltip>
            
            <v-tooltip left v-if="toolbar.deliveryFee && getAccessSettings() == 1">
                <template v-slot:activator="{ on }">
                    <a>
                        <v-btn rounded color="#274761" dark small class="mt-3 mb-3 mr-3" v-on="on" @click="insertDeliveryFee()">
                            <v-icon class="ml-1" size="18" color="#fff" style="font-weight: bolder;">mdi-cart</v-icon>
                        </v-btn>
                    </a>
                </template>
                <span>Add Delivery Fee</span>
            </v-tooltip>
            
            <v-tooltip left v-if="toolbar.insertDialog && getAccessSettings() == 1">
                <template v-slot:activator="{ on }">
                    <a>
                        <v-btn rounded color="#274761" dark small class="mt-3 mb-3" v-on="on" @click="insertDialog()">{{ $t('global.create') }}
                            <v-icon class="ml-1" size="18" color="#fff" style="font-weight: bolder;">mdi-pencil-plus-outline</v-icon>
                        </v-btn>
                    </a>
                </template>
                <span>Insert New Data</span>
            </v-tooltip>
            
            <v-tooltip left v-if="toolbar.edit && getAccessSettings() == 1">
                <template v-slot:activator="{ on }">
                    <a>
                        <v-btn rounded color="#274761" dark small class="mt-3 mb-3" v-on="on" @click="editCategory()">{{ $t('global.edit') }}
                            <v-icon class="ml-1" size="18" color="#fff" style="font-weight: bolder;">mdi-pencil-plus-outline</v-icon>
                        </v-btn>
                    </a>
                </template>
                <span>Insert New Category</span>
            </v-tooltip>
            <v-tooltip left v-if="toolbar.editAccessRight">
                <template v-slot:activator="{ on }">
                    <a>
                        <v-btn rounded color="#274761" dark small class="mt-3 mb-3" v-on="on" @click="editCategory()">{{ $t('global.edit') }}
                            <v-icon class="ml-1" size="18" color="#fff" style="font-weight: bolder;">mdi-pencil-plus-outline</v-icon>
                        </v-btn>
                    </a>
                </template>
                <span>Category Data</span>
            </v-tooltip>
            <template  v-if="toolbar.hasSearch">
                <a-input
                        class="ml-5"
                        left
                        v-model="search"
                        :placeholder="$t('global.search')"
                        style="width: 250px"
                    >
                </a-input>
                <v-icon color="#274761" style="font-size: 15px; font-weight: bolder;" class="ml-1">mdi-magnify</v-icon>
            </template>
            <!-- <v-tooltip left v-if="toolbar.back">
                <template v-slot:activator="{ on }">
                    <a>
                        <v-btn class="mb-3" icon v-on="on">
                            <v-icon size="22" color="#274761" style="font-weight: bolder;">mdi-arrow-left</v-icon>
                        </v-btn>
                    </a>
                </template>
                <span>Back</span>
            </v-tooltip> -->
        </template>
    </v-list-item>      
</template>
<script>
import { mapActions, mapState } from 'vuex'

    export default {
        props: {
            toolbar: {
                type: Object,
            },
            order: {
                type: Object
            },
        },

        data: () => ({
        }),
        computed: {
            ...mapState(['search', 'accessRightData']),
            search: {
                get() {
                    return this.$store.state.search;
                },
                set(val) {
                    return this.$store.state.search = val;
                }
            },
        },
        methods: {
            ...mapActions(['getAccessRights']),
            // getAccessSettings() {
            //     // const user = this.loggedInUser;
            //     const pageMetaId = this.$route.meta.id;

                
            //     console.log(pageMetaId, 'metaId')
            //     console.log(this.loggedInUser, 'USER')
            //     console.log(this.loggedInUser.access.length, 'USER')


            //     // if(user.access.length != 8) {

            //     // }

            //     // let hasAccess = 0;
            //     // let userData = this.loggedInUser

            //     // if(userData.access.includes(this.$route.meta.id)) {
            //     //     const findAcess = this.accessRightData.find(res => {
            //     //         if(res.access_right == 2 || res.access_right == 4) {
            //     //             return res.account_type == userData.account_type && res.sub_group == userData.sub_group
            //     //         }
            //     //     })

            //     //     if(findAcess) {
            //     //         hasAccess = 1
            //     //     }
            //     // }
            //     // return hasAccess

            //     return [];
            // },
            insertDialog() {
                this.$emit('insertDialog');
            },
            backToMasterCategory() {
                this.$emit('backToMasterCategory')
            },
            editCategory() {
                this.$emit('editCategory');
            },
            insertDeliveryFee(){
                this.$emit('insertDeliveryFee')
            },
            toggleSearch() {
                this.$emit('toggleSearch');
            },
            toggleBackHistory() {
                this.$emit('toggleBackHistory');
            }
            
        },
        created() {
            // this.getAccessRights();
        },
    }

</script>
<style scoped>

</style>